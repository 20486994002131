/* START TEXT ANIMATION -- https://codepen.io/postor/pen/vYpNYg */
.arrows {
    width: 80px;
    height: 70px;
}

.arrows path {
    stroke: #000;
    fill: transparent;
    stroke-width: 1px;
}

.arrows path.a1 {
    stroke: rgba(0, 0, 0, 0.5);
    animation: fade1 2s infinite;
    -webkit-animation: fade1 2s infinite;
}

.arrows path.a2 {
    stroke: rgba(0, 0, 0, 0.3);
    animation: fade2 2s infinite;
    -webkit-animation: fade2 2s infinite;
}

.arrows path.a3 {
    stroke: rgba(0, 0, 0, 0.1);
    animation: fade3 2s infinite;
    -webkit-animation: fade3 2s infinite;
}

.start-text span {
    font-weight: 100;
    font-size: 1.25rem;
    letter-spacing: -0.08em;
    display: block;
}

.start-text span.a1 {
    color: rgba(0, 0, 0, 0.5);
    animation: fade1 2s infinite;
    -webkit-animation: fade1 2s infinite;
}

.start-text span.a2 {
    color: rgba(0, 0, 0, 0.3);
    animation: fade2 2s infinite;
    -webkit-animation: fade2 2s infinite;
}

.start-text span.a3 {
    color: rgba(0, 0, 0, 0.1);
    animation: fade3 2s infinite;
    -webkit-animation: fade3 2s infinite;
}

@media only screen and (min-width: 768px) {
    .start-text span {
        font-size: 1.375rem;
        letter-spacing: -0.08em;
    }
}

@keyframes fade1 {
    0% {
        stroke: rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.5);
    }
    18% {
        stroke: rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.5);
    }
    30% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    85% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    100% {
        stroke: rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.5);
    }
}

@keyframes fade2 {
    0% {
        stroke: rgba(0, 0, 0, 0.3);
        color: rgba(0, 0, 0, 0.3);
    }
    20% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    25% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    30% {
        stroke: rgba(0, 0, 0, 0.3);
        color: rgba(0, 0, 0, 0.3);
    }
    100% {
        stroke: rgba(0, 0, 0, 0.3);
        color: rgba(0, 0, 0, 0.3);
    }
}

@keyframes fade3 {
    0% {
        stroke: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.1);
    }
    5% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    10% {
        stroke: rgba(0, 0, 0, 1);
        color: rgba(0, 0, 0, 1);
    }
    15% {
        stroke: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.1);
    }
    100% {
        stroke: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.1);
    }
}

@-webkit-keyframes fade /*Safari and Chrome*/
{
    0% {
        opacity: 0
    }
    40% {
        opacity: 1
    }
    80% {
        opacity: 0
    }
    100% {
        opacity: 0
    }
}
