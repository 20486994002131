@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white;
}
