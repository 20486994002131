:root {
    --iconUrl: url('assets/images/icon_fitting_room.png');
    --progressWidth: 20%;
}

strong {
    font-weight: 100;
}

h1, h2 {
    font-size: 20px;
    font-weight: 300;
}

p, h3, h4 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1.15em;
}

button, textarea {
    /*to let the accessibility work on buttons*/
    word-spacing: inherit;
}

.start-title p {
    letter-spacing: 0.09em;
    font-weight: lighter;
    font-size: 1.375rem;
}

.start-title h1 {
    margin-top: 0.625rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.625rem;
    text-transform: uppercase;
    line-height: 2.188rem;
}

.question-text {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 2.188rem;
    font-size: 1.625rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    h1 {
        font-size: 30px;
    }

    p {
        font-size: 45px;
    }

    .start-title p {
        font-size: 1.875rem;
    }

    .start-title h1 {
        font-size: 2.5rem;
        line-height: 2.813rem;
    }
}

.generic-white-btn {
    text-transform: uppercase;
    font-size: .8rem;
    background-color: white;
    color: black;
    padding: .8rem .5rem;
    width: 100%;
}

.continue-btn,
.prev-btn,
.finish-btn {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: -0.03em;
    padding: .9rem .5rem;
    width: 100%;
}

.continue-btn {
    font-weight: 200;
    background-color: black;
    border: 1px solid black;
    color: white;
}

.prev-btn {
    background-color: white;
    border: 1px solid black;
    color: black;
}

.finish-btn {
    background-color: white;
    color: black;
    padding: .2rem .5rem;
}

.empty-btn {
    width: 100%;
    height: 2.5rem;
}

.empty-finish-btn {
    width: 100%;
    height: 2.1rem;
}

.tellMore {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .5);
    padding: .7rem .5rem;
    resize: none;
}

.tellMore::placeholder {
    color: rgba(0, 0, 0, .2);
}

.tellMore:focus {
    outline: none;
    border: 1px solid black;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .continue-btn,
    .prev-btn,
    .finish-btn {
        font-size: 1.1rem;
        padding: 18px .5rem;
    }

    .finish-btn {
        padding: .5rem;
    }

    .tellMore.lg {
        width: 300px;
    }
}

.whiteBtn {
    min-width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.01rem;
    padding: 0.875rem 1.375rem;
    border: 1px solid rgba(0, 0, 0, .5);
}

.yesNo {
    min-width: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .whiteBtn {
        font-size: 1rem;
    }

    .btn-checkbox {
        min-width: 100%;
    }
}

.whiteBtn.lg {
    width: 100%;
}

.whiteBtn.selected {
    color: white;
    background-color: black;
}

.area-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    border: 1px solid transparent;
}

.area-option-item {
    width: 60px;
    gap: 5px;
}

@media screen and (max-height: 720px) {
    .area-option-item {
        width: 56px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .area-option-item {
        width: 88px;
    }
}

.finish-text {
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 0.09em;
    text-transform: uppercase;
}

.finish-text.first {
    font-size: 3rem;
    text-align: center;
    font-weight: 200;
    letter-spacing: 0;
    margin-bottom: 2rem;
    overflow-wrap: break-word;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .finish-text {
        font-size: 2rem;
        text-align: center;
    }

    .finish-text.first {
        font-size: 4rem;
        text-align: center;
    }
}

.progress-container {
    width: 100%;
    height: 8px;
    border: 1px solid black;
    border-radius: 20px;
    overflow: hidden;
}

.progress-bar {
    background-color: black;
    border-radius: 20px;
    height: 6px;
    border: 1px solid black;
    width: var(--progressWidth);
    transition: width 2s ease;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);
}

@keyframes widthAnimation {
    0% {
        width: 10%;
    }
    100% {
        width: var(--progressWidth);
    }
}

.service {
    --iconUrl: url('assets/images/icon_service.png');
}

.sizes {
    --iconUrl: url('assets/images/icon_sizes.png');
}

.online {
    --iconUrl: url('assets/images/icon_online.png');
}

.fitting_room {
    --iconUrl: url('assets/images/icon_fitting_room.png');
}

.till {
    --iconUrl: url('assets/images/icon_till.png');
}

.area-icon::after {
    margin-left: 5px;
    content: "";
    width: 35px;
    height: 30px;
    display: inline-block;
    background: var(--iconUrl);
    background-repeat: no-repeat;
    background-size: 100%;
}

.check-icon {
    width: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .area-icon::after {
        width: 40px;
        height: 40px;
        background-size: 120%;
    }

    .check-icon {
        width: 30px;
    }
}


.rating {
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    gap: .5rem;
}

.checkbox p {
    max-width: 120px;
    flex: 1;
}


/* Transitions */
.page-transition {
    animation-name: fade-in;
    animation-duration: 2s;
    opacity: 1;
}

.question-transition {
    animation-name: fade-in;
    animation-duration: 1s;
    opacity: 1;
}

.modal-transition {
    animation-name: fade-up;
    animation-duration: .5s;
    opacity: 1;
}

.move-down-transition {
    animation-name: fade-down;
    animation-duration: .5s;
    opacity: 1;
}

.start-transition {
    animation: fade-up-start 1s;
    opacity: 1;
}

.start-transition.second {
}

.start-transition.third {
    animation: fade-up-start-delayed 1s;
}

.language-transition {
    animation-name: fade-in-language;
    animation-duration: 2s;
    opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .page-transition {
        animation-name: fade-in;
        animation-duration: 2.5s;
    }
}

@keyframes fade-in-tablet {
    from {
        opacity: 0.1;
        /*transform: translateX(20%);*/
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0.2;
        /*transform: translateX(20%);*/
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fade-up {
    from {
        /*opacity: 0;*/
        transform: translateY(20%);
    }
    to {
        /*opacity: 1;*/
        transform: translateY(0);
    }
}

@keyframes fade-down {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-up-start {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-up-start-delayed {
    0% {
        opacity: 0;
        transform: translateY(20%);
    }
    60% {
        opacity: 0;
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in-language {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Modal */
.modal:focus {
    outline: none;
}

.modal p {
    font-weight: lighter;
    font-size: .8rem;
    font-family: inherit;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    line-height: 1.3;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .modal p {
        font-size: 1.2rem;
    }
}

.modal h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

/*Media queries*/
@media (max-height: 667px) {
    .modal h2 {
        margin-bottom: 1rem;
    }

    .finish-text.first {
        font-size: 3rem;
    }
}


.locale-text {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 1.125rem;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    user-select: none;
}

.slide-in {
    transform: translateX(-50%);
    animation: slide-in 0.5s forwards;
}

.exit {
    animation: slide-out 0.5s forwards;
}

.wheel-container div {
    background-color: transparent !important;
    width: 100% !important;
}

.wheel-text {
    font-size: 26px;
    color: white;
    font-weight: 500;
    letter-spacing: 0;
    overflow-wrap: break-word;
    padding: 10px 50px;
}

.wheel-text.unselected {
    opacity: 0.8;
    font-weight: 300;
    font-size: 20px;
}

.language-title {
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
}

@keyframes slide-in {
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}


/*Accessibility*/
body {
    --_access-icon-border-radius: 50%;
    --_access-icon-width: 42px;
    --_access-icon-height: 42px;
    --_access-icon-font: 30px / 40px "Material Icons";
    --_access-icon-bg: #000;
    --_access-menu-item-icon-increase-text-spacing: 'home';
    --_access-icon-right: 20px;
    --_access-icon-bottom: 25px;
    --_access-menu-width: 100%;
    --_access-menu-border-radius: 0px;
    --_access-menu-item-button-padding: 20px 0;
    --_access-menu-background-color: rgba(0, 0, 0, 0.98);
    --_access-menu-header-color: white;
    --_access-menu-item-button-active-background-color: rgba(255, 255, 255, 0.3);
    --_access-menu-border: none;
    --_access-menu-transition-duration: 500ms;
    --_access-menu-left: 0;
    --_access-menu-right: 0;
    --_access-menu-item-button-before-collapse-opacity: 1;
}

body ._access-menu.close {
    bottom: -100%;
    right: 0;
    left: 0;
    width: 100%;
}

body ._access-icon {
    box-shadow: none;
}

body ._access-menu ul {
    gap: 0;
}

body ._access-menu {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px 30px;
}

body ._access-menu ._text-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

body ._access-menu ._menu-btn {
    position: relative;
    top: 0;
}

body ._access-menu ul li button {
    --_access-menu-item-button-background: rgba(0, 0, 0, 0);
    --_access-menu-item-button-border: none;
    --_access-menu-item-button-border-radius: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    color: white;
    font-weight: 200;
}

body ._access-menu ul li button::before {
    color: white;

}

body ._access-menu ul li button[data-access-action="increaseText"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'zoom_in';
    --_access-menu-item-icon-increase-text-top: 15px;
}

body ._access-menu ul li button[data-access-action="decreaseText"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'zoom_out';
    --_access-menu-item-icon-decrease-text-spacing-top: 15px;
}

body ._access-menu ul li button[data-access-action="increaseTextSpacing"]:before,
body ._access-menu ul li button[data-access-action="increaseLineHeight"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'unfold_more';
    --_access-menu-item-icon-increase-text-spacing-top: 23px;
    --_access-menu-item-icon-increase-line-height-top: 15px;
}

body ._access-menu ul li button[data-access-action="decreaseTextSpacing"]:before,
._access-menu ul li button[data-access-action="decreaseLineHeight"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'unfold_less';
    --_access-menu-item-icon-decrease-text-spacing-top: 23px;
    --_access-menu-item-icon-decrease-line-height-top: 15px;
}

body ._access-menu ul li button[data-access-action="invertColors"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'invert_colors';
    --_access-menu-item-icon-invert-colors-top: 15px;
}

body ._access-menu ul li button[data-access-action="grayHues"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'format_color_reset';
    --_access-menu-item-icon-gray-hues-top: 16px;
}

body ._access-menu ul li button[data-access-action="textToSpeech"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'record_voice_over';
    --_access-menu-item-icon-text-to-speech-top: 16px;
}

body ._access-menu ul li button[data-access-action="speechToText"]:before {
    font-family: 'Material Icons', sans-serif;
    content: 'mic';
}

body ._access-menu ._menu-close-btn,
body ._access-menu ._menu-reset-btn {
    font-family: 'Material Icons', sans-serif !important;
    color: white;
}

.star-button {
    transition: all 0.2s ease;
}

.star-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

:focus-visible {
    outline: 3px solid #2563eb;
    outline-offset: 2px;
    border-radius: 4px;
    box-shadow: 0 0 0 2px white;
    transition: outline-offset 0.1s ease;
}

.focus-hide:focus {
    outline: none;
    box-shadow: none;
}

/* Ensure minimum contrast ratio of 3:1 for focus indicator */
/*button:focus-visible,*/
/*a:focus-visible,*/
/*[role="button"]:focus-visible {*/
/*    outline-color: #2563eb;*/
/*    position: relative;*/
/*    z-index: 1;*/
/*}*/

/*!* Ensure focus indicator meets minimum area requirement *!*/
/*.min-focus-area:focus-visible {*/
/*    outline-width: 4px;*/
/*    outline-offset: 4px;*/
/*}*/

/*!* Special handling for picker items *!*/
/*.wheel-text:focus-visible {*/
/*    outline: 3px solid #ffffff;*/
/*    outline-offset: 2px;*/
/*    border-radius: 2px;*/
/*}*/

/*!* Ensure focus remains visible during interactions *!*/
/*:focus {*/
/*    outline: none;*/
/*}*/

/*:focus:not(:focus-visible) {*/
/*    outline: none;*/
/*    box-shadow: none;*/
/*}*/
