/* --- SETUP DIFFERENT FONT FAMILY ACCORDING TO LOCALE --- */
@font-face {
    font-family: ABCMonumentGroteskThin;
    src:local('Assets/Fonts/ABCMonumentGrotesk-Thin.otf');
}
@font-face {
    font-family: ABCMonumentGroteskMedium;
    src:local('Assets/Fonts/ABCMonumentGrotesk-Medium.otf');
}
@font-face {
    font-family: ABCMonumentGroteskBold;
    src:local('Assets/Fonts/ABCMonumentGrotesk-Bold.otf');
}
@font-face {
    font-family: ABCMonumentGroteskBlack;
    src:local('Assets/Fonts/ABCMonumentGrotesk-Black.otf');
}
@font-face {
    font-family: MPlus1;
    src: local('Assets/Fonts/MPLUS1p-Regular.ttf');
}

@font-face {
    font-family: NotoSansSC;
    src: local('Assets/Fonts/NotoSansSC-Regular.ttf');
}

@font-face {
    font-family: NotoSansTC;
    src: local('Assets/Fonts/NotoSansTC-Regular.ttf');
}

@font-face {
    font-family: IBMPlexSansKR;
    src: local('Assets/Fonts/IBMPlexSansKR-Regular.ttf');
}

.abcMonumentGrotesk {
    font-family: ABCMonumentGroteskRegular, sans-serif;
}

.abcMonumentGrotesk .light {
    font-family: ABCMonumentGroteskThin, sans-serif;
}

.mplus1p {
    font-family: MPlus1, sans-serif;
}

.notoSansSC {
    font-family: NotoSansSC, sans-serif;
}

.notoSansTC {
    font-family: NotoSansTC, sans-serif;
}

.ibmPlex {
    font-family: IBMPlexSansKR, sans-serif;
}
